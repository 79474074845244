<!-- eslint-disable -->
<template>
  <div id="earn" class="token-sale p-tb c-l black-bg white-sec">
    <div class="container mt-6">
      <div id="about" class="about-section style-2 p-tb">
        <div class="row align-items-center">
          <div class="col-md-8 about-left align-self-baseline">
            <div class="sec-title"><h1>Altex Offers</h1></div>
            <div v-show="false" class="row align-items-center">
              <div class="col-md-2">
                <button class="el-btn" @click="onShow()">Show</button>
              </div>
              <div class="col-md-3">
                <div class="form-row">
                  <div class="col-md-12">
                    <input v-model="discount" type="text" class="form-control" placeholder="Set discount value">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FAQ Section start-->
        <div id="faq" class="faq-section p-t">
          <div class="container">
            <div class="category-list row" v-for="items, i in getFilteredList" :key="`${items.name}-${i}`">
              <div class="category-name col-md-12" v-if="items.products.length">{{ items.name }} ({{ items.products.length }} produse)</div>
              <div v-for="item, j in items.products" :key="`${item.id}-${j}`" class="products-item col-md-3 m-4 justify-center">
                <div class="flex flex-col">
                  <div class="Product-photoWrapper"><img :src="getImageUrl(item.image)" class="offer-image mb-4" alt=""></div>
                  <span class="item-name">{{ item.name }}</span>
                </div>
                <div class="mb-0 mt-2 mt-auto mt-2">
                  <div class="font-bold inline-block">
                    <div class="text-base d-flex relative align-center mb-1">
                      <div class="font-medium has-line-through"><span class="old-price leading-none">{{ item.regular_price.toString().split('.')[0] }}</span><sup class="inline-block -tracking-0.33 sub-old-price">,{{ item.regular_price.toString().split('.')[1] }}</sup></div>
                      <div class="inline discount">-{{getDiscountString(item.price, item.regular_price)}}</div>
                    </div>
                    <div class="leading-none text-red-brand "><span><span class="new-price leading-none">{{ item.price.toString().split('.')[0] }}</span>
                    <sup class="inline-block -tracking-0.33 sub-new-price">,{{ item.price.toString().split('.')[1] }}</sup>
                    <span class="leading-none new-price"> lei</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <!-- FAQ Section end-->
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default Vue.extend({
    name: 'get-offers',
    data: () => ({
      urlToFetch: [
        'https://fenrir.altex.ro/catalog/category/telefoane?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/tablete?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/huse-telefon?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/smartwatches?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/bratari-fitness?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/smart-rings?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/accesorii-wearables?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/boxe-portabile-telefoane-tablete?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/huse-tablete?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/accesorii-diverse-tablete?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/baterii-externe-telefon?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/incarcatoare-wireless?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/incarcatoare-telefon?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/suport-auto-telefon?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cabluri-adaptoare-telefon?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/folii-protectie-telefon?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/carduri-memorie-telefon?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/laptopuri?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/laptopuri?filter=brand-3334:apple&?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/monitoare-pc?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/multifunctionale?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/imprimante?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/hdd-ssd-externe?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/televizoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/sisteme-audio?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/boxe-portabile-telefoane-tablete?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/soundbar-home-cinema?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/combine-frigorifice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/frigidere?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aparate-frigorifice-incorporabile?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/side-by-side?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/congelatoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/lazi-frigorifice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/vitrine-frigorifice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/racitoare-vin?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/racitoare-vin?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/masini-spalat-vase-electrocasnice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/masini-spalat-vase-independente?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/masini-spalat-vase-incorporabile?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/masini-spalat-vase-compacte?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/hote-traditionale?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/hote-decorative?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/hote-incorporabile?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cuptoare-microunde-incorporabile?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cuptoare-incorporabile?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/plite-incorporabile?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/masini-spalat-rufe?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/expresoare-cafea-incorporabile?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/accesorii-consumabile-electrocasnice-mari?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aragazuri?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/uscatoare-rufe-automate-clasice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aspiratoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/saci-aspiratoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/consumabile-aspiratoare-ingrijirea-locuintei?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cuptoare-microunde?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cuptoare-electrice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/gratare-electrice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/plite-electrice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/prepararea-mesei?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/ustensile-bucatarie?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/fiare-calcat?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aparate-calcat-vertical?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/statii-calcat-ingrijirea-tesaturii?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/mese-calcat-ingrijirea-tesaturii?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/accesorii-consumabile-fiare-statii-calcat?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/roboti-bucatarie-electrocasnice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/blendere-mini-tocatoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/blendere-mini-tocatoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/mixere?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/masini-tocat?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cantare-bucatarie?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/feliatoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aparate-vidat?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/servirea-mesei?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/prajitoare-paine?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/prajitoare-sandwich-waffe?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/multicooker?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/friteuze?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/masini-copt-paine?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aparate-gatit-in-aburi-deshidratoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aparate-pentru-desert?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/expresoare-cafea?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cafetiere?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/rasnite-cafea?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/fierbatoare-apa?sort=best_deal&dir=desc',
        'https://fenrir.altex.ro/catalog/category/consumabile-aparate-cafea?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/storcatoare-fructe-legume?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cani-filtrante?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aer-conditionat?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/ventilator?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/ventilatoare-baie?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/centrale-termice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/pompe-caldura?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/boilere?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/sobe-seminee-si-cazane-combustibil-solid?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/accesorii-climatizare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/spalare-intretinere-rufe?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/produse-curatare-baie?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/sobe-seminee-si-cazane-combustibil-solid?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/curatenie-casa-interior-exterior?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/produse-curatare-bucatarie?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/bauturi-fine?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aparate-ras?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aparate-tuns-barba?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/vitamine-minerale-suplimente?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/manichiura-pedichiura?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/epilatoare-clasice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/periute-dinti-electrice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/irigatoare?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/produse-ingrijirea-corpului?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/make-up?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/tensiometre?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/termometre-articole-sanatate?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/aparate-cosmetice?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/cantare-baie?sort=best_deal&dir=desc&size=100',
        'https://fenrir.altex.ro/catalog/category/igiena-ingrijire-copii?sort=best_deal&dir=desc&size=100',
      ],
      products: [],
      filteredProducts: [],
      discount: null,
    }),
    computed: {
      getFilteredList() {
        return this.discount ? this.filteredProducts : this.products;
      },
    },
    mounted() {
      let index = 0;
      const interval = setInterval(() => {
        this.onFetch(this.urlToFetch[index]);
        index += 1;
        if (index >= this.urlToFetch.length) {
          clearInterval(interval); // Stop when the array is fully iterated
        }
      }, 3000); // 2000ms (2 second) delay
    },
    methods: {
      filterByDiscount(items, minDiscount) {
        return items
          .map(({ name, products }) => ({
            name,
            products: products.filter(
              (
                { price, regular_price: regularPrice },
              ) => this.getDiscount(price, regularPrice) >= minDiscount,
            ),
          }))
          .filter(({ products }) => products.length > 0); // Remove objects with empty products
      },
      filterProducts(products) {
        return products.filter(
          (
            { price, regular_price: regularPrice },
          ) => this.getDiscount(price, regularPrice) >= 25,
        );
      },
      onFetch(url) {
        fetch(`https://corsproxy.io/?${url}`)
          .then(response => response.json())
          .then((data) => {
            const { category: { name }, products } = data;
            const filteredProducts = this.filterProducts(products);
            this.products.push({ name, products: filteredProducts });
          })
          .catch(error => console.error(error));
      },
      getDiscountString(price, oldPrice) {
        const discount = ((oldPrice - price) / oldPrice) * 100;
        return `${discount.toFixed(0)}%`;
      },
      getDiscount(price, oldPrice) {
        const discount = ((oldPrice - price) / oldPrice) * 100;
        return discount.toFixed(0);
      },
      getImageUrl(path) {
        return `https://lcdn.altex.ro/${path}`;
      },
      onShow() {
        const { discount } = this;

        if (!discount) return;
        const filteredProducts = this.filterByDiscount(this.products, discount);
        this.filteredProducts = filteredProducts;
      },
    },
  });
</script>

<style>
  .lead {
    color: #ffffff !important;
  }

  .leaderboard-container {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    color: #bbbbbb;
    cursor: pointer;
    display: table;
    margin-bottom: 30px;
    padding: 30px;
    transition: all 500ms;
  }

  .leaderboard-container h2 {
    border-bottom: 3px solid #ffffff;
    color: #ffffff;
  }

  .leaderboard-container li {
    background-color: transparent !important;
    color: #ffffff;
    font-size: 24px;
  }

  .faq-section {
    background-color: #000000;
  }

  #earn .card,
  #earn .card-header {
    background-color: #000000 !important;
    color: #ffffff !important;
  }

  #earn .card-header h5 {
    color: #ffffff !important;
  }

  .align-self-baseline {
    align-self: baseline;
  }

 .products-item {
    min-height: 400px;
    border-width: 2px;
    background-color: white;
    border: 2px solid grey;
    border-radius: 5px;
    padding:8px;
  }

  .offer-image {
    width: 200px;
  }

  .old-price {
    color: #000;
    margin-top: 10px;
    line-height: 18px;
    letter-spacing: -0.33px;
    letter-spacing: -.56px;
    font-weight: 500;
    font-size: 24px;
  }

  .sub-old-price {
    color: #000;
    font-weight: 700;
    font-size: 12.6px;
  }

  .sub-new-price {
    font-weight: 700;
    font-size: 14px;
    color: #c00033;
  }

  .has-line-through::before {
    border-top: 1px solid #000;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 40%;
    width: 40px;
  }

  .new-price {
    color: #c00033;
    letter-spacing: -.56px;
    font-weight: 700;
    font-size: 24px;
  }

  .relative {
    position: relative;
  }

  .discount {
    background-color: #f5cb38;
    color: #000;
    font-weight: 700;
    padding: 0px;
    margin-left: 6px;
  }

  .item-name {
    line-height: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #000 !important;
  }

  .mt-2 {
    margin-top: 8px !important;
  }

  .category-name {
    font-size: 32px;
    color: #fff;
  }

</style>

